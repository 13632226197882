import React, { useEffect, useState } from 'react'
import LeftAside from '../components/LeftAside'
import Nav from '../components/Nav'
import Fade from 'react-reveal/Fade';
import { Loader, Modal, Accordion, Icon } from 'semantic-ui-react'
import API from '../api'
import { useParams } from "react-router-dom";
import TopicData from '../components/TopicData'
export default function Subjects() {
  const [activeIndex, setActiveIndex] = useState();
  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    // const { activeIndex } = activeIndex
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }
  const { id } = useParams();
  const [subjectList, setSubjectList] = useState([]);
  const [open, setOpen] = React.useState(false)

  const [loader, setLoader] = useState(true);
  const getSubjectList = () => {
    setLoader(true)
    let user_id = localStorage.getItem('id')
    const form = new FormData();
    form.append('user_id', user_id);
    form.append('semester_id', id);
    API.post('/topic_video', form)
      .then(function (response) {
        console.log(response)
        setSubjectList(response.data.data)
        setLoader(false)
      })
  }
  useEffect(() => {
    getSubjectList()
  }, [])
  return (

    < div >
      <div className="landing">
        <div className="ui grid" >
          <LeftAside />
          <div className="ten wide computer sixteen wide mobile column" >
            <aside>
              <div className="right-aside">
                <Nav />
                <div className="inner">
                  <h1>Select Subject</h1>
                  <div className="panel">
                    {subjectList ? (<>
                      <div className="">
                        <Accordion>
                          {loader ? (<>
                            <div className='loadingContainer'>
                              <Loader active inline='centered' />
                            </div>
                          </>) : (<>
                            {subjectList.map((d, i) => (
                              <>
                                <Accordion.Title
                                  active={activeIndex === i}
                                  index={i}
                                  key={i}
                                  onClick={handleClick}
                                >
                                  <h4
                                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span><img src={require('../assets/img/img1.png')} alt="" />{d.subject_name}</span>
                                    <i class="dropdown icon"></i>
                                  </h4>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === i}> <Fade><TopicData data={d.topic_data} /></Fade></Accordion.Content>
                                {/* <div class="title" key={i}> */}
                                {/* {d.topic_data.map((l) => (
                                    <>
                                      <li id={l.topic_id}>
                                        <Modal
                                          onClose={() => setOpen(false)}
                                          onOpen={() => setOpen(true)}
                                          open={open}
                                          trigger={<a>{l.topic_name}</a>}
                                        >
                                          <Modal.Header>Select a Photo</Modal.Header>
                                          <Modal.Content image>
                                            <Modal.Description>
                                              <ul>
                                                {l.video_data.map((v) => (
                                                  <><li id={v.video_id}><a>{v.video_name}</a></li><span>{v.video_link}</span></>
                                                ))}
                                              </ul>
                                            </Modal.Description>
                                          </Modal.Content>

                                        </Modal>



                                      </li>
                                    </>
                                  ))} */}
                                {/* </ul> */}
                                {/* </div> */}
                              </>
                            ))}
                          </>)}
                        </Accordion>
                      </div>
                    </>) : (<><h5>No subjects in this semester</h5></>)}
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div >
  )
}
