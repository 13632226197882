import React, { useState } from 'react'
import LeftAside from '../components/LeftAside'
import banner from '../assets/img/ishm-01.jpg'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form, Message, Button } from 'semantic-ui-react'
import API from '../api'
import Fade from 'react-reveal/Fade';


export default function Login() {
  let history = useHistory();
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [success, setSucces] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true)
    setSucces(false)
    setInvalid(false)
    const form = new FormData();
    form.append('mobile_number', mobileNumber);
    form.append('password', password);
    API.post('/login', form)
      .then(function (response) {
        if (response.data.result === 1) {
          setLoading(false)
          setInvalid(false)
          setSucces(true)
          localStorage.setItem('id', response.data.data.id);
          localStorage.setItem('registered_id', response.data.data.registered_id);
          localStorage.setItem('name', response.data.data.name);
          localStorage.setItem('contact_number', response.data.data.contact_number);
          localStorage.setItem('email', response.data.data.email);
          localStorage.setItem('alternate_number', response.data.data.alternate_number);
          localStorage.setItem('qualification', response.data.data.qualification);
          localStorage.setItem('session_id', response.data.data.session_id);
          localStorage.setItem('semester_id', response.data.data.semester_id);
          localStorage.setItem('dob', response.data.data.dob);
          localStorage.setItem('blood_group', response.data.data.blood_group);
          localStorage.setItem('city', response.data.data.city);
          localStorage.setItem('state', response.data.data.state);
          localStorage.setItem('address', response.data.data.address);
          localStorage.setItem('pincode', response.data.data.pincode);
          localStorage.setItem('father_name', response.data.data.father_name);
          localStorage.setItem('mother_name', response.data.data.mother_name);
          localStorage.setItem('referred_by', response.data.data.guardian_contact);
          localStorage.setItem('remarks', response.data.data.remarks);
          localStorage.setItem('status', response.data.data.status);
          localStorage.setItem('profile_image', response.data.data.profile_image);
          localStorage.setItem('course_name', response.data.data.course_name);
          localStorage.setItem('gender', response.data.data.gender);
          history.push({
            pathname: "/",
          })
          // this.authenticated = true;
          console.log(response)
        } else if (response.data.result === 0) {
          setLoading(false)
          setSucces(false)
          setInvalid(true)
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <Fade>
      <div className="landing">
        <div className="ui grid" >
          <div className="eight wide computer sixteen wide mobile column" >
            <aside>
              <div className="left-aside" >
                <Fade>
                  <div className="login">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Link to="/" className="logo"><img src={require('../assets/img/logo.png')} alt="" /></Link>
                      <a onClick={() => history.goBack()}>
                        <svg class="svg-icon" viewBox="0 0 20 20">
                          <path fill="none" d="M18.271,9.212H3.615l4.184-4.184c0.306-0.306,0.306-0.801,0-1.107c-0.306-0.306-0.801-0.306-1.107,0
	L1.21,9.403C1.194,9.417,1.174,9.421,1.158,9.437c-0.181,0.181-0.242,0.425-0.209,0.66c0.005,0.038,0.012,0.071,0.022,0.109
	c0.028,0.098,0.075,0.188,0.142,0.271c0.021,0.026,0.021,0.061,0.045,0.085c0.015,0.016,0.034,0.02,0.05,0.033l5.484,5.483
	c0.306,0.307,0.801,0.307,1.107,0c0.306-0.305,0.306-0.801,0-1.105l-4.184-4.185h14.656c0.436,0,0.788-0.353,0.788-0.788
	S18.707,9.212,18.271,9.212z"></path>
                        </svg>
                      </a>
                    </div>
                    <h1>Welcome to ISHM Student, <br />Please login to your account.</h1>
                    <Form onSubmit={handleSubmit}>
                      <Form.Field>
                        <label>Mobile Number</label>
                        <input
                          placeholder='Mobile Number'
                          maxlength="10" minlength="10" pattern="[0-9]*"
                          required
                          onChange={e => setMobileNumber(e.target.value)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Password</label>
                        <input
                          type="password"
                          placeholder='Password'
                          required
                          onChange={e => setPassword(e.target.value)}
                        />
                      </Form.Field>
                      {invalid && (<>
                        <Fade >
                          <Message
                            error
                            header='Invalid Credentials!'
                          />
                        </Fade>
                      </>)}
                      {success && (<>
                        <Fade >
                          <Message
                            success
                            header='Succesfully signed in!'
                          />
                        </Fade>
                      </>)}
                      <Form.Field>
                        <Button type="submit" className="btn2" loading={loading} disabled={loading}>Login</Button>
                        <a style={{ color: '#000', cursor: 'pointer' }} onClick={() => history.goBack()}>Cancel</a>
                      </Form.Field>
                    </Form>
                  </div>
                </Fade>
              </div>
            </aside>
          </div>
          <div className="eight wide computer sixteen wide mobile column left_side_container" >
            <div className="left-aside" style={{ backgroundImage: "url(" + banner + ")" }}>
              <article>
                <h1>ISHM E-LEARNING PLATFORM</h1>
                <p>International School of Hospitality Management, Kolkata is one of the finest institute in eastern India for Airlines, Hotel, Hospitality and Business Management related course with 100% placement service to each and every students.
    ISHM Startred an E Learning Initiative for the betterment of student during this COVID-19 pandemic. With finest faculty in we have created the video Courses for each and very subject with thier minute details. Lets us all learn and defeat the COVID-19. </p>
                {/* <a href="#" className="ui button btn">Explore</a> */}
              </article>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}
