import React from 'react';
import logo from './logo.svg';
import './App.css';
import './assets/layout.css';
import './assets/responsive.css';
import 'semantic-ui-css/semantic.min.css'

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from './components/protect_routes';

import Landing from './views/Landing'
import ScrollToTop from './components/ScrollToTop'
import News_and_notice from './views/News_and_notice'
import Login from './views/Login'
import Courses from './views/Courses'
import Holiday from './views/Holiday'
import VideoCourses from './views/VideoCourses'
import FeeReminder from './views/FeeReminder'
import Profile from './views/Profile'
import SemesterList from './views/SemesterList'
import Subjects from './views/Subjects'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <div className="allContent">
          <Switch>
            <Route path="/" component={Landing} exact />
            <Route path="/news_and_notice" component={News_and_notice} exact />
            <Route path="/login" component={Login} exact />
            <Route path="/courses" component={Courses} exact />
            <Route path="/holiday" component={Holiday} exact />
            <ProtectedRoute path="/video_courses" component={VideoCourses} exact />
            <ProtectedRoute path="/semester_list" component={SemesterList} exact />
            <ProtectedRoute path="/profile" component={Profile} exact />
            <ProtectedRoute path="/subjects/:id" component={Subjects} exact />
            <Route path="/fee_reminder" component={FeeReminder} exact />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
