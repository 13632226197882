import React, { useState } from 'react'
import { Loader, Modal, Accordion, Icon } from 'semantic-ui-react'
import YouTube from 'react-youtube';
import Fade from 'react-reveal/Fade';

export default function VideoData(props) {
    const [activeIndex, setActiveIndex] = useState();
    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        // const { activeIndex } = activeIndex
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
    }
    const opts = {
        height: '300',
        width: '100%',
    }
    return (
        <div>
            <ul className='videoData'>
                {props.videoData.length ? (<>
                    <Accordion>
                        {props.videoData.map((v, i) => (
                            <>
                                <Accordion.Title
                                    active={activeIndex === i}
                                    index={i}
                                    key={i}
                                    onClick={handleClick}
                                >{v.video_name} <Icon name='dropdown' /></Accordion.Title>
                                <Accordion.Content active={activeIndex === i}>
                                    <Fade>
                                        <YouTube opts={opts} videoId={v.video_link} />
                                    </Fade>
                                    {/* <iframe frameborder='0'
                                        allow='autoplay; encrypted-media'
                                        allowfullscreen
                                        title='video' src={`https://www.youtube.com/watch?` + }
                                    /> */}
                                    {/* {v.video_link} */}
                                </Accordion.Content>
                                {/* <li id={v.video_id}><a></a></li><span></span> */}

                            </>
                        ))}
                    </Accordion>
                </>) : (<><h5>No Videos available</h5></>)}

            </ul>
        </div>
    )
}
