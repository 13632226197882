import React from 'react'
import LeftAside from '../components/LeftAside'
import Nav from '../components/Nav'
import Fade from 'react-reveal/Fade';

export default function Courses() {
    const data = [
        {
            name: 'ADVANCE DIPLOMA in Aviation, Hotel &amp; Hospitality Management (2 Years)',
            url: 'https://www.ishm.co.in/ADVANCE-DIPLOMA-in-Aviation,-Hotel-&-Hospitality-Management-(2-Years)'
        }, {
            name: 'DIPLOMA in Aviation, Hotel &amp; Hospitality Management (1 Year)',
            url: 'https://www.ishm.co.in/DIPLOMA-in-Aviation,-Hotel-&-Hospitality-Management-(1-Year)'
        }, {
            name: 'B.B.A in Hospitality Management (3 Years)',
            url: 'https://www.ishm.co.in/B.B.A-in-Hospitality-Management-(3-Years)'
        }, {
            name: 'BHM + BTS DUAL DEGREE in HOTEL MANAGEMENT (3 Years)',
            url: 'https://www.ishm.co.in/BHM-+-BTS-DUAL-DEGREE-in-HOTEL-MANAGEMENT-(3-Years)'
        }, {
            name: 'BHM Hotel Management (3 Years)',
            url: 'https://www.ishm.co.in/BHM-Hotel-Management-(3-Years)'
        }, {
            name: 'B.B.A in Aviation Management',
            url: 'https://www.ishm.co.in/B.B.A-in-Aviation-Management'
        }, {
            name: 'B.B.A (Bachelor of Business Administration) with Entrepreneurship',
            url: 'https://www.ishm.co.in/B.B.A-(Bachelor-of-Business-Administration)-with-Entrepreneurship'
        }, {
            name: 'BACHELOR in Hospital Administration',
            url: 'https://www.ishm.co.in/BACHELOR-in-Hospital-Administration'
        }, {
            name: 'PGPBM  MBA (2 Years)',
            url: 'https://www.ishm.co.in/PGPBM--MBA-(2-Years)'
        }, {
            name: 'MASTERS in Hospital Administration',
            url: 'https://www.ishm.co.in/MASTERS-in-Hospital-Administration-'
        },
    ]
    return (
        <>
            <div className="landing">
                <div className="ui grid">
                    <LeftAside />
                    <div className="ten wide computer sixteen wide mobile column">
                        <aside>
                            <div className="right-aside">
                                <Nav />
                                <Fade>
                                    <div className="inner">
                                        <h1>Courses</h1>
                                        <div className="panel">
                                            {data.map((d, i) => (
                                                <article className="notice" key={i}>
                                                    <p><a style={{ color: '#000' }} href={d.url} target="_blank">{d.name}</a></p>
                                                </article>
                                            ))}
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </>
    )
}
