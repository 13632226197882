import React, { useEffect, useState } from 'react'
import LeftAside from '../components/LeftAside'
import Nav from '../components/Nav'
import Fade from 'react-reveal/Fade';
import { useHistory } from "react-router-dom";
import { Button } from 'semantic-ui-react'

export default function Profile() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [registeredId, setRegisteredId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [alternateNumber, setAlternateNumber] = useState('');
    const [courseName, setCourseName] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [blood_group, setBloodGroup] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [pin, setPin] = useState('');
    const [sstate, setSstate] = useState('');
    useEffect(() => {
        let id = localStorage.getItem('id')
        let name = localStorage.getItem('name')
        let regId = localStorage.getItem('registered_id')
        let phoneNum = localStorage.getItem('contact_number')
        let email = localStorage.getItem('email')
        let dob = localStorage.getItem('dob')
        let gender = localStorage.getItem('gender')
        let altNum = localStorage.getItem('alternate_number')
        let bloodG = localStorage.getItem('blood_group')
        let address = localStorage.getItem('address')
        let city = localStorage.getItem('city')
        let pin = localStorage.getItem('pincode')
        let state = localStorage.getItem('state')
        let courseName = localStorage.getItem('course_name')
        if (id) {
            setName(name)
            setRegisteredId(regId)
            setPhoneNumber(phoneNum)
            setEmail(email)
            setCourseName(courseName)
            setDob(dob)
            setGender(gender)
            setBloodGroup(bloodG)
            setAddress(address)
            setCity(city)
            setPin(pin)
            setSstate(state)
            setAlternateNumber(altNum)
        } else {
            console.log('not working')
        }
    })
    const logout = () => {
        setLoading(true)

        localStorage.removeItem('id');
        localStorage.removeItem('registered_id');
        localStorage.removeItem('name');
        setTimeout(() => history.push({
            pathname: "/",
        }), 2000);


    }
    return (
        <Fade>
            <div className="landing">
                <div className="ui grid" >
                    <LeftAside />
                    <div className="ten wide computer sixteen wide mobile column" >
                        <aside>
                            <div className="right-aside">
                                <Nav />
                                <div className="inner">
                                    <h1>Profile</h1>
                                    <div className="edit">
                                        <div><img src={require('../assets/img/user.png')} /></div>
                                        <div>
                                            <article>
                                                <small>Student Id- {registeredId}</small>
                                                <h4>{name}</h4>
                                                <small>{courseName}</small>
                                            </article>
                                        </div>
                                        <div>
                                            <Button className="btn2" onClick={logout} loading={loading} disabled={loading}><i className="fa fa-power-off"></i> Logout</Button>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <h4>Personal Details </h4>
                                        <div className="info">
                                            <div>
                                                <h6>DOB</h6>
                                                <small>{dob}</small>
                                            </div>
                                            <div>
                                                <h6>Gender</h6>
                                                <small>{gender}</small>
                                            </div>
                                            <div>
                                                <h6>Blood Group</h6>
                                                <small>{blood_group}</small>
                                            </div>
                                        </div>
                                        <div className="details">
                                            <h4>Others</h4>
                                            <ul>
                                                <li>
                                                    <h5>Phone Number</h5>
                                                    <span>{phoneNumber}</span>
                                                </li>
                                                <li>
                                                    <h5>Email Address</h5>
                                                    <span>{email}</span>
                                                </li>
                                                <li>
                                                    <h5>Address</h5>
                                                    <span>{address}</span>
                                                </li>
                                                <li>
                                                    <h5>City</h5>
                                                    <span>{city}</span>
                                                </li>
                                                <li>
                                                    <h5>Pin code</h5>
                                                    <span>{pin}</span>
                                                </li>
                                                <li>
                                                    <h5>state</h5>
                                                    <span>{sstate}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </Fade>
    )
}
