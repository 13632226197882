import React, { useEffect, useState } from 'react'
import LeftAside from '../components/LeftAside'
import Nav from '../components/Nav'
import Fade from 'react-reveal/Fade';
import { Accordion, Icon, Loader } from 'semantic-ui-react'
import API from '../api'

export default function Holiday() {
  const [activeIndex, setActiveIndex] = useState();
  const [holidayList, setHolidayList] = useState([]);
  const [loader, setLoader] = useState(true);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    // const { activeIndex } = activeIndex
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }
  const getHolidayList = () => {
    setLoader(true)
    API.get('/holiday_list')
      .then(function (response) {
        console.log(response)
        setHolidayList(response.data.data)
        setLoader(false)
      })
  }
  useEffect(() => {
    getHolidayList()
  }, [])
  return (
    <>
      <div className="landing">
        <div className="ui grid" >
          <LeftAside />
          <div className="ten wide computer sixteen wide mobile column" >
            <aside>
              <div className="right-aside">
                <Nav />
                <Fade>
                  <div className="inner">
                    <h1>Holiday lists</h1>
                    <div className="panel">
                      <Accordion>
                        {loader ? (<>
                          <div className='loadingContainer'>
                            <Loader active inline='centered' />
                          </div>
                        </>) : (<>
                          {holidayList ? (<>

                            {holidayList.map((d, i) => (
                              <>
                                <Accordion.Title
                                  active={activeIndex === i}
                                  index={i}
                                  key={i}
                                  onClick={handleClick}
                                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                  <span><img src={require('../assets/img/img3.png')} alt="" />  {d.month_name}</span>
                                  <Icon name='dropdown' />
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === i}>
                                  {d.month_data.map((l) => (
                                    <>
                                      <Fade bottom>
                                        <h4>{l.title}</h4>
                                        <p>{l.date}</p>
                                      </Fade>
                                    </>
                                  ))}
                                </Accordion.Content>
                              </>
                            ))}
                          </>) : (<>
                            <h5>No Holiday list available</h5>
                          </>)}
                        </>)}

                      </Accordion>
                    </div>
                  </div>
                </Fade>

              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  )
}
