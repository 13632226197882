import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import VideoData from '../components/VideoData'
import Fade from 'react-reveal/Fade';

export class TopicData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: 0,
        };
    }
    getModal = value => {
        this.setState({ showModal: value });
    };
    hideModal = value => {
        this.setState({ showModal: 0 });
    };
    render() {
        return (
            <ul>
                {this.props.data ? (<>

                    {this.props.data.map((d, i) => (
                        <>
                            <li className="topicName">
                                <a onClick={() => this.getModal(d.topic_id)}>{d.topic_name}</a>
                                <Fade>
                                    <Modal
                                        closeIcon
                                        onClose={() => this.hideModal(d.topic_id)}
                                        // onOpen={this.state.showModal === d.topic_id}
                                        open={this.state.showModal === d.topic_id}
                                    >
                                        <Modal.Header>{d.topic_name}</Modal.Header>
                                        <Modal.Content scrolling>
                                            <Modal.Description>
                                                <VideoData videoData={d.video_data} />
                                            </Modal.Description>
                                        </Modal.Content>
                                    </Modal>
                                </Fade>
                            </li>
                        </>
                    ))}
                </>) : (<>No topics</>)}

            </ul>
        )
    }
}

export default TopicData
