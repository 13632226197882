import React, { useEffect, useState } from 'react'
import banner from '../assets/img/ishm-01.jpg'
import LeftAside from '../components/LeftAside'
import Fade from 'react-reveal/Fade';
import { Loader } from 'semantic-ui-react'
import Nav from '../components/Nav'

import API from '../api'
export default function News_and_notice() {
    const [noticeList, setNoticeList] = useState([]);
    const [loader, setLoader] = useState(true);
    const getNoticeList = () => {
        setLoader(true)
        API.get('/news_notice')
            .then(function (response) {
                console.log(response)
                setNoticeList(response.data.data)
                setLoader(false)
            })
    }
    useEffect(() => {
        getNoticeList()
    }, [])
    return (
        <>


            <div className="landing">
                <div className="ui grid">
                    <LeftAside />
                    <div className="ten wide computer sixteen wide mobile column">
                        <aside>
                            <div className="right-aside">
                                <Nav />
                                <Fade>
                                    <div className="inner">
                                        <h1>News & Notice</h1>
                                        <div className="panel">
                                            {loader ? (<>
                                                <div className='loadingContainer'>
                                                    <Loader active inline='centered' />
                                                </div>
                                            </>) : (<>

                                                {noticeList ? (<>
                                                    {noticeList.map((d, i) => (
                                                        <>
                                                            <article className="notice" key={i}>
                                                                <ul>
                                                                    <li>
                                                                        <img src={d.image} alt="" />
                                                                        <div className="card">
                                                                            <small>{d.date}</small>
                                                                            <h5>{d.title}</h5>
                                                                            <span>{d.description}</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </article>
                                                        </>
                                                    ))}

                                                </>) : (<><h5>No Notice Available </h5></>)}

                                            </>)}

                                        </div>
                                    </div>
                                </Fade>

                            </div>
                        </aside>
                    </div>
                </div>
            </div>

        </>
    )
}
