import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../services/auth";

export const ProtectedRoute = ({
    component: Component,
    ...rest
}) => {
    var auth = localStorage.getItem('id');
    console.log(auth)
    return (
        <Route
            {...rest}
            render={props => {
                if (auth !== null) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }
            }}
        />
    );
};
