import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import LeftAside from '../components/LeftAside'
import Nav from '../components/Nav'
import Fade from 'react-reveal/Fade';
import { Icon, Loader, Segment } from 'semantic-ui-react'
import API from '../api'

export default function SemesterList() {
  const [semesterList, setSemesterList] = useState([]);
  const [loader, setLoader] = useState(true);
  const getSemesterList = () => {
    setLoader(true)
    let user_id = localStorage.getItem('id')
    const form = new FormData();
    form.append('user_id', user_id);
    API.post('/semester_list', form)
      .then(function (response) {
        console.log(response)
        setSemesterList(response.data.data)
        setLoader(false)
      })
  }
  useEffect(() => {
    getSemesterList()
  }, [])
  return (
    <div>
      <div className="landing">
        <div className="ui grid" >
          <LeftAside />
          <div className="ten wide computer sixteen wide mobile column semesters">
            <aside>
              <div className="right-aside">
                <Nav />
                <div className="inner">
                  <h1>Select Semester</h1>
                  <div className="panel">
                    <Fade>
                      {loader ? (<>
                        <div className='loadingContainer'>
                          <Loader active inline='centered' />
                        </div>
                      </>) : (<>

                        {semesterList ? (<>

                          {semesterList.map((d, i) => (
                            <>
                              <div className="ui styled accordion" key={i}>
                                <Link to={`/subjects/` + d.semester_id}>
                                  <div className="title">
                                    <h4
                                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <span> <img src={require('../assets/img/img2.png')} alt="" /> {d.semester_name} </span> <i className="dropdown icon"></i></h4>
                                  </div>
                                </Link>
                              </div>
                            </>
                          ))}

                        </>) : (<><h5>No Semesters Available</h5></>)}

                      </>)}

                    </Fade>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  )
}
