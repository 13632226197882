import React from 'react'
import { Link } from "react-router-dom";
import LeftAside from '../components/LeftAside'
import Nav from '../components/Nav'
import Fade from 'react-reveal/Fade';

export default function Home() {

    return (
        <>
            <div className="landing">
                <div className="ui grid" >
                    <LeftAside />
                    <aside className="ten wide computer sixteen wide mobile column">
                        <div className="right-aside">
                            <Nav landing={true} />
                            <Fade>
                                <main>
                                    <h1>ISHM-India's Best Hotel <br /> Management School</h1>
                                    <div className="grid-list">
                                        <div><a href="https://www.ishm.co.in/Home/about" target="_blank"><span><img src={require('../assets/img/ishm-08.png')} alt="" /></span><h5>About Us</h5></a></div>
                                        <div><a href="https://www.ishm.co.in/Home/album" target="_blank"><span><img src={require('../assets/img/ishm-05.png')} alt="" /></span><h5>Gallery</h5></a></div>
                                        <div><Link to='/news_and_notice'><span><img src={require('../assets/img/ishm-02.png')} alt="" /></span><h5>News & notice</h5></Link></div>
                                        <div><Link to="/courses"><span><img src={require('../assets/img/ishm-04.png')} alt="" /></span><h5>Courses</h5></Link></div>
                                        <div><Link to="/fee_reminder"><span><img src={require('../assets/img/ishm-09.png')} alt="" /></span><h5>Fee Reminder</h5></Link></div>
                                        <div><Link to="/semester_list"><span><img src={require('../assets/img/ishm-06.png')} alt="" /></span><h5>Video Course</h5></Link></div>
                                        <div><Link to="/holiday"><span><img src={require('../assets/img/ishm-03.png')} alt="" /></span><h5>Holiday</h5></Link></div>
                                        <div><a href="https://www.ishm.co.in/Home/contact_us" target="_blank"><span><img src={require('../assets/img/ishm-07.png')} alt="" /></span><h5>Contact us</h5></a></div>
                                    </div>
                                </main>
                            </Fade>
                        </div>
                    </aside>
                </div>
            </div>
        </>
    )
}
