import React from 'react'
import banner from '../assets/img/ishm-01.jpg'
import { Link } from "react-router-dom";

export default function LeftAside() {
    return (
        <>
            <aside className="six wide computer sixteen wide mobile column left_side_container">
                <div className="left-aside" style={{ backgroundImage: "url(" + banner + ")" }}>
                    <Link to="/" className="logo"><img src={require('../assets/img/logo.png')} alt="" /></Link>
                    <article>
                        <h1>ISHM E-LEARNING PLATFORM</h1>
                        <p>International School of Hospitality Management, Kolkata is one of the finest institute in eastern India for Airlines, Hotel, Hospitality and Business Management related course with 100% placement service to each and every students.
    ISHM Startred an E Learning Initiative for the betterment of student during this COVID-19 pandemic. With finest faculty in we have created the video Courses for each and very subject with thier minute details. Lets us all learn and defeat the COVID-19. </p>
                        {/* <a href="#" className="ui button btn">Explore</a> */}
                    </article>
                </div>
            </aside>
        </>
    )
}
