import React from 'react'
import LeftAside from '../components/LeftAside'
import Nav from '../components/Nav'
import Fade from 'react-reveal/Fade';

export default function VideoCourses() {
    return (
        <Fade>
            <div className="landing">
                <div className="ui grid" >
                    <LeftAside />
                    <div className="ten wide computer sixteen wide mobile column" >
                        <aside>
                            <div className="right-aside">
                                <Nav />
                                <div className="inner">

                                    <div className="ui grid">
                                        <div className="eight wide computer sixteen wide mobile column ">
                                            <h1>Resturant Business</h1>
                                            <iframe width="100%" height="200px" src="https://www.youtube.com/embed/f2V6_VN3XaE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        <div className="eight wide computer sixteen wide mobile column">
                                            <h1>Video List</h1>
                                            <div className="panel">
                                                <article className="notice video">
                                                    <ul>
                                                        <li>
                                                            <img src={require('../assets/img/video.jpg')} alt="" />
                                                            <div className="card">

                                                                <h5>Layout F & B Area</h5>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <img src={require('../assets/img/video.jpg')} alt="" />
                                                            <div className="card">

                                                                <h5>Organization of Article</h5>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <img src={require('../assets/img/video.jpg')} alt="" />
                                                            <div className="card">

                                                                <h5>Resturant Planning</h5>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <img src={require('../assets/img/video.jpg')} alt="" />
                                                            <div className="card">

                                                                <h5>Layout F & B Area</h5>

                                                            </div>
                                                        </li>
                                                    </ul>
                                                </article>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </Fade>
    )
}
