import React from 'react'
import LeftAside from '../components/LeftAside'
import Nav from '../components/Nav'
import Fade from 'react-reveal/Fade';

export default function FeeReminder() {
    return (
        <div className="landing">
            <div className="ui grid" >
                <LeftAside />
                <div className="ten wide computer sixteen wide mobile column" >
                    <aside>
                        <div className="right-aside">
                            <Nav />
                            <Fade>
                                <div className="inner">
                                    <h1>Fee reminder</h1>
                                    <p>Coming soon</p>
                                </div>
                            </Fade>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
}
